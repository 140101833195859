import { rightsActions } from '@/src/modules/common/actions/rights.actions';
import { EntityActions } from '@/src/modules/common/models/common.models';
import { countHoursBetweenDates, groupBy } from '@/src/modules/common/utils';
import {
	EventContent,
	EventDatesType,
	EventEntity,
	EventModule,
	EventModuleRightsTypes,
	EventModules,
	EventPeriod,
} from '@/src/modules/events/models/events.models';
import { ProjectEntity } from '@/src/modules/events/models/projects.models';
import { SeasonEntity } from '@/src/modules/events/models/seasons.models';
import moment from 'moment';

const getSeasonTypeNumber = (season: SeasonEntity) => {
	switch (season.type) {
		case 'spring':
			return 0;
		case 'summer':
			return 1;
		case 'autumn':
			return 2;
		case 'winter':
			return 3;
		default:
			return 4;
	}
};

export const eventsActions = {
	convertDaysArrayToRecord: (array: { date: Date; format: EventDatesType }[]) => {
		const initialValue = {};
		return array.reduce((obj, item) => {
			const key = moment(item.date).format('MM/DD/YYYY');
			return {
				...obj,
				[key]: item.format,
			};
		}, initialValue);
	},
	convertDaysArrayToRecord2: (array: { date: Date; format: EventDatesType }[]) => {
		const initialValue = {};
		return array.reduce((obj, item) => {
			const key = moment(item.date).format('YYYY-MM-DD');
			return {
				...obj,
				[key]: item.format,
			};
		}, initialValue);
	},
	sortPeriodsListByDate: (
		events: {
			content?: EventContent;
		}[],
		order: 'asc' | 'desc' = 'desc',
		sortField: 'startsAt' | 'endsAt' = 'startsAt',
	): any[] =>
		events.sort((a, b) => {
			if (!b?.content?.data?.period || !a?.content?.data?.period) {
				return -1;
			}
			if (b.content?.data?.period[sortField] > a.content?.data?.period[sortField]) {
				return order === 'desc' ? 1 : -1;
			} else {
				return order === 'desc' ? -1 : 1;
			}
		}),
	sortSeasonByYearVersion: (a?: SeasonEntity, b?: SeasonEntity) => {
		if (!b) {
			return -1;
		}
		if (!a) {
			return 1;
		}
		if (a.year && b.year) {
			if (a.year > b.year) {
				return -1;
			}
			if (a.year < b.year) {
				return 1;
			}
			if (a.year === b.year) {
				if (getSeasonTypeNumber(a) > getSeasonTypeNumber(b)) {
					return -1;
				} else {
					return 1;
				}
			}
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return a.id - b.id;
	},
	comparatorEventByDate: (a?: EventEntity, b?: EventEntity) => {
		if (!a?.id && !b?.id) {
			return -1;
		}

		if (!a?.id) {
			return 1;
		}

		if (!b?.id) {
			return -1;
		}

		if (b?.content?.data?.period?.startsAt === a?.content?.data?.period?.startsAt) {
			return b.id - a.id;
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (b?.period?.startsAt > a?.period?.startsAt) {
			return 1;
		} else {
			return -1;
		}
	},
	getSeasonPeriod: (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		seasonId,
		eventsList: EventEntity[],
	): { period: EventPeriod; needToArchive: boolean } | undefined => {
		const seasonMap = eventsActions.getSeasonsEventsMap(eventsList);
		if (seasonMap?.has(seasonId)) {
			const events = seasonMap.get(seasonId);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const max = eventsActions.sortPeriodsListByDate(events, 'desc', 'endsAt')[0];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const min = eventsActions.sortPeriodsListByDate(events, 'asc')[0];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const needToArchive = events.some((e) => e.isFinished && e.status !== 'ARCHIVED');

			if (min && max) {
				return {
					period: {
						startsAt: min.period?.startsAt,
						endsAt: max.period?.endsAt,
					},
					needToArchive,
				};
			}
		}
		return undefined;
	},

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	getSeasonsEventsMap: (events) => groupBy(events, (event: EventEntity) => event.season?.id),

	findCurrentSeasons(getSeasonsArray: SeasonEntity[]) {
		const seasons = getSeasonsArray.filter((s) => s);

		let result = seasons.find((s) =>
			moment().isBetween(moment(s.period?.startsAt), moment(s.period?.endsAt)),
		);

		// if correct seasons don't found
		if (!result) {
			result = seasons[0];

			if (result) {
				let diff: number = countHoursBetweenDates(
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					new Date(result.period?.startsAt),
					new Date(),
				);

				if (diff < 0) {
					return result;
				}

				seasons.forEach((s) => {
					const newDiff = countHoursBetweenDates(
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						new Date(s?.period?.startsAt),
						new Date(),
					);

					if (newDiff > 0 && newDiff < diff) {
						diff = newDiff;
						result = s;
					}
				});
			}
		}

		return result;
	},

	isFinished(event: EventEntity) {
		if (!event?.content?.data?.period?.endsAt) {
			return false;
		}
		return moment(event.content?.data?.period.endsAt).add(1, 'day').isBefore(moment());
	},

	transformEvent(
		event: EventEntity,
		season?: SeasonEntity,
		project?: ProjectEntity,
	): EventEntity {
		return {
			...event,
			isFinished: eventsActions.isFinished(event),
			season,
			project,
		};
	},

	sortSeasonById:
		(order: 'asc' | 'desc' | 'none' = 'none') =>
		(a: SeasonEntity, b: SeasonEntity): number => {
			// TODO
			// 'spring' | 'summer' | 'autumn' | 'winter'

			const yearA = a.year;
			const yearB = b.year;

			if (order === 'none' || !yearA || !yearB) {
				return 0;
			}

			if (yearB > yearA) {
				return order === 'desc' ? 1 : -1;
			} else {
				return order === 'desc' ? -1 : 1;
			}
		},

	filterByText:
		(text: string | undefined, caseInsensitive = true) =>
		(event: EventEntity): boolean => {
			if (text === undefined) {
				return true;
			}

			const include = (searchIn?: string | number | null | undefined) =>
				caseInsensitive
					? searchIn?.toString()?.toLowerCase()?.includes(text?.toLowerCase())
					: searchIn?.toString()?.includes(text);

			const startsWith = (searchIn?: string | number) =>
				caseInsensitive
					? searchIn?.toString()?.toLowerCase()?.startsWith(text)
					: searchIn?.toString()?.startsWith(text);

			return Boolean(
				include(event.content?.data?.title?.ru) ||
					include(event.content?.data?.title?.en) ||
					include(event.project?.content?.data?.title?.ru) ||
					include(event.project?.content?.data?.title?.en) ||
					startsWith(event.id),
			);
		},
	addRightsToProject: (data: EntityActions & ProjectEntity): ProjectEntity => ({
		...data,
		rights: {
			canPatch: rightsActions.canPatch(data),
			canListEvents: rightsActions.canListNestedResource(data, 'event'),
			canCreateEvent: rightsActions.canCreateNestedResource(data, 'event'),
			canListMembers: rightsActions.canListNestedResource(data, 'member'),
			canAddMember: rightsActions.canNestedResource(data, 'member', 'register'),
		},
	}),
	someModuleHasRight: (modules: EventModule[] | undefined, right: EventModuleRightsTypes) =>
		modules?.some((m) => m.rights?.[right]),
	addRightsToEventModule: (modules?: (EventModule & EntityActions)[]) =>
		modules?.map(
			(m) =>
				({
					...m,
					rights: {
						canViewProgramModule: rightsActions.canNestedResource(
							m,
							'program',
							'access',
						),
						canViewPublicationsModule: rightsActions.canNestedResource(
							m,
							'publication',
							'access',
						),
						canListProposalsForPublications: rightsActions.canListNestedResource(
							m,
							'publication/proposal',
						),
						canViewScheduleModule: rightsActions.canNestedResource(
							m,
							'schedule',
							'access',
						),
						canListScheduleVersions: rightsActions.canListNestedResource(
							m,
							'schedule/version',
						),
						canListProposalsForSchedule: rightsActions.canListNestedResource(
							m,
							'schedule/proposal',
						),
						canViewTeamModule: rightsActions.canNestedResource(m, 'team', 'access'),
						canListMembers: rightsActions.canListNestedResource(m, 'team/member'),

						canViewEventReportModule: rightsActions.canNestedResource(
							m,
							'activity-report',
							'access',
						),
						canViewEventActivityFeedbackModule: rightsActions.canNestedResource(
							m,
							'activity-feedback',
							'access',
						),
						canViewScheduleBroadcastModule: rightsActions.canNestedResource(
							m,
							'schedule-broadcast',
							'access',
						),
						canViewScheduleSupportModule: rightsActions.canNestedResource(
							m,
							'schedule-support',
							'access',
						),
					},
				}) as EventModule,
		),
	addRightsToEvent: (data: EntityActions & EventModules): EventEntity => ({
		...data,
		modules: eventsActions.addRightsToEventModule(data.modules),
		rights: {
			canRead: rightsActions.canRead(data),
			canPatch: rightsActions.canPatch(data),
			canArchive: rightsActions.can(data, 'archive'),
			canAddMember: rightsActions.canNestedResource(data, 'member', 'register'),
			canFindMember: rightsActions.canNestedResource(data, 'member', 'find'),
			canListMembers: rightsActions.canNestedResource(data, 'member', 'list'),
		},
	}),
	addRightsToSeason: (data: EntityActions & SeasonEntity): SeasonEntity => ({
		...data,
		rights: {
			canPatch: rightsActions.canPatch(data),
			canDelete: rightsActions.canDelete(data),
			canListEvents: rightsActions.canListNestedResource(data, 'event'),
		},
	}),
};

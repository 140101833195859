import { workspaceApiPath } from '@/src/modules/workspaces/routes/workspaces.api.routes';

export const projectEventsRoutes = {
	eventsApiPath: `/events`,
	workspacesEventsApiPath: (workspaceId: string) => `${workspaceApiPath(workspaceId)}/events`,
	projectsApiPath: (workspaceId: string) => `${workspaceApiPath(workspaceId)}/projects`,
	projectApiPath: (projectId: string) => `/projects/${projectId}`,
	seasonsApiPath: (workspaceId: string) => `${workspaceApiPath(workspaceId)}/seasons`,
	seasonApiPath: (seasonId: number) => `/seasons/${seasonId}`,
	eventApiPath: (eventId: number) => `${projectEventsRoutes.eventsApiPath}/${eventId}`,
	eventArchiveApiPath: (eventId: number) =>
		`${projectEventsRoutes.eventApiPath(eventId)}/archive`,
	eventPatchApiPath: (eventId: number) => `${projectEventsRoutes.eventApiPath(eventId)}`,
};

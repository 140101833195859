import { EntityActions, ServerAction } from '@/src/modules/common/models/common.models';
import { ActionModel, ActionResource, ActionType } from '@/src/modules/common/models/rights.models';

const hasActions = (o?: object): ServerAction[] => {
	if (!o) {
		return [];
	}
	if ('actions' in o) {
		return o.actions as ServerAction[];
	}
	return [];
};

export const extractActionsFromAPI = (o?: any): (null | ActionModel)[] =>
	hasActions(o).map((a) => {
		const parsed = a.name.split(':');

		if (!parsed[0] || !parsed[1]) {
			return null;
		}

		const resources: string[] = parsed[0]?.split('/');
		const resource: string | undefined = resources[0];

		if (!resource) {
			return null;
		}

		const nestedResources: string[] = resources.slice(1);

		return {
			resource,
			nestedResources,
			action: parsed[1],
		} as ActionModel;
	});

const can = (o: EntityActions | undefined, action: ActionType) => {
	if (!o) {
		return false;
	}
	return (
		extractActionsFromAPI(o).find(
			(a: ActionModel | null) => a?.action === action && a?.nestedResources.length === 0,
		) !== undefined
	);
};

const canNestedResource = (
	o: EntityActions | undefined,
	nestedResource: ActionResource,
	action: ActionType,
) => {
	if (!o) {
		return false;
	}
	return (
		extractActionsFromAPI(o).find(
			(a: ActionModel | null) =>
				a?.action === action && a?.nestedResources.join('/') === nestedResource,
		) !== undefined
	);
};

export const rightsActions = {
	can,
	canRead: (o?: EntityActions) => can(o, 'read'),
	canPatch: (o?: EntityActions) => can(o, 'patch'),
	canDelete: (o?: EntityActions) => can(o, 'delete'),
	canNestedResource,
	canListNestedResource: (o: EntityActions | undefined, nestedResource: ActionResource) =>
		canNestedResource(o, nestedResource, 'list'),
	canCreateNestedResource: (o: EntityActions | undefined, nestedResource: ActionResource) =>
		canNestedResource(o, nestedResource, 'create'),
	canReadNestedResource: (o: EntityActions | undefined, nestedResource: ActionResource) =>
		canNestedResource(o, nestedResource, 'read'),
	canPatchNestedResource: (o: EntityActions | undefined, nestedResource: ActionResource) =>
		canNestedResource(o, nestedResource, 'patch'),
	canDeleteNestedResource: (o: EntityActions | undefined, nestedResource: ActionResource) =>
		canNestedResource(o, nestedResource, 'delete'),
};

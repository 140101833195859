'use client';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useGET, UseGETReloadFunction } from '@/src/modules/common/hooks/useGET';
import { usePOST } from '@/src/modules/common/hooks/usePOST';
import { ApiMultiResponse, ApiOnceResponse } from '@/src/modules/common/models/common.models';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { CreateProjectRequest } from '@/src/modules/events/models/events.create.models';
import { ProjectEntity } from '@/src/modules/events/models/projects.models';
import { projectEventsRoutes } from '@/src/modules/events/routes/events.api.routes';

export type UseProjects = {
	projects?: ProjectEntity[];
	isLoading: boolean;
	reload: UseGETReloadFunction;
	error: unknown;
	addProject: (project: CreateProjectRequest) => Promise<ProjectEntity | undefined>;
};

export const useProjects = (workspaceId?: string, canGet = true, canAdd = true): UseProjects => {
	const [endpoints, setEndpoints] = useState<{ get: string; add: string }>();
	const [projects, setProjects] = useState<ProjectEntity[]>();

	const { data, isLoading, reload, error } = useGET<ApiMultiResponse<ProjectEntity>, unknown>({
		endpoint: endpoints?.get,
		canGet: endpoints?.get !== undefined && canGet,
	});

	const addProjectCommand = usePOST<CreateProjectRequest, ApiOnceResponse<ProjectEntity>>({
		endpoint: endpoints?.add,
	});

	useEffect(() => {
		if (workspaceId) {
			setEndpoints({
				get: projectEventsRoutes.projectsApiPath(workspaceId),
				add: projectEventsRoutes.projectsApiPath(workspaceId),
			});
		}
	}, [workspaceId]);

	useEffect(() => {
		if (data?.data) {
			setProjects(data.data.map(eventsActions.addRightsToProject));
		}
	}, [data?.data]);

	const addProject = useCallback((project: CreateProjectRequest) => {
		if (!canAdd) {
			return Promise.resolve(undefined);
		}
		// TODO write logic
		return addProjectCommand(project).then((response) => {
			if (response) {
				return response.data;
			}
			return undefined;
		});
	}, []);

	return useMemo(
		() => ({
			projects,
			isLoading,
			reload,
			error,
			addProject,
		}),
		[
			{
				projects,
				isLoading,
				reload,
				error,
				addProject,
			},
		],
	);
};
